// import ChessButton from '@shared/components/chess/chessButton'
import CustomLink from '@shared/components/customLink'
import styles from './style.module.scss'
import React from 'react'

function HILWidget() {
  return (
    <CustomLink href={'/hockey-india-league'}>
    <div className={styles.container}>
      <div className={styles.btn}>
        {/* <ChessButton title="View More" /> */}
      </div>
    </div>
    </CustomLink>
  )
}

export default HILWidget
